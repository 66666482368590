<template>

  <v-container class="home">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card flat id="widget-container">
      <communes></communes>
          </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import communes from '@/components/communes.vue'

export default {
  name: 'home',
  data: function () {
    return {
    }
  },
  components: {
    communes
  },
  methods: {
    getCommunes () {
      axios({
        method: 'GET',
        url: this.appParameters.url + 'communes',
        responseType: 'json'
      })
        .then(response => {
          this.$store.dispatch('store_communes', response.data)
        })
    },
    getAgenda () {
      axios({
        method: 'GET',
        url: this.appParameters.urlAgenda + 'events',
        responseType: 'json'
      })
        .then(response => {
          this.$store.dispatch('store_googleagenda', response.data[0])
        })
    }
  },
  mounted () {
    if (this.conseils.communes == null) this.getCommunes()
    if (this.conseils.googleAgenda == null) this.getAgenda()
  },
  computed: {
    ...mapState(['appParameters','conseils'])
  }
}
</script>

<style scoped>
.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-label {
  left: 0px !important;
  right: auto !important;
  position: absolute;
}
</style>