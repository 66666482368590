<template>

<v-layout>
<v-flex v-show="!confirmMessage">
  <form id="conseil-form" @submit.prevent="contactSubmit">
    <v-textarea justify-left class="txtf"
      v-model="nom"
      :error-messages="nomErrors"
      label="Nom"
      @input="$v.nom.$touch()"
      @blur="$v.nom.$touch()"
      required
      rows="1"
      no-resize
      outlined
    ></v-textarea>
    <v-textarea
      v-model="prenom"
      label="Prenom"
      @input="$v.prenom.$touch()"
      @blur="$v.prenom.$touch()"
      required
      rows="1"
      no-resize
      outlined
    ></v-textarea>
    <v-textarea
      v-model="email"
      error-count="3"
      :error-messages="emailErrors"
      label="E-mail"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
      required
      rows="1"
      no-resize
      outlined
    ></v-textarea>

    <v-textarea
      v-model="telephone"
      error-count="3"
      :error-messages="telephoneErrors"
      label="Téléphone"
      @input="$v.telephone.$touch()"
      @blur="$v.telephone.$touch()"
      required
      rows="1"
      no-resize
      outlined
    ></v-textarea>

        <!--<vue-tel-input v-model="telephone"></vue-tel-input>    -->
    <v-textarea
      v-model="message"
      :error-messages="messageErrors"
      label="Sujet à aborder"
      @input="$v.message.$touch()"
      @blur="$v.message.$touch()"
      required
      outlined
    ></v-textarea>


    <v-btn class="mr-4" @click="contactSubmit">envoyer la demande</v-btn>
  </form>
</v-flex>

<v-card v-show="confirmMessage">
<v-card-text>
  {{ confirmText }}
  <v-divider></v-divider>
  <strong>Rappel de vos informations</strong><br/>
  <v-label justify-left>Nom</v-label> : {{ nom }}<br/>
  <v-label>Prénom</v-label> : {{ prenom }}<br/>
  <v-label>Email</v-label> : {{ email }}<br/>
  <v-label>Téléphone</v-label> : {{ telephone }}<br/>
  <v-label>Votre message</v-label> : {{ message }}<br/>
  <v-divider></v-divider>
  <strong>Le lieu de rendez-vous</strong><br/>
  Commune  : {{ selected.cityName }}<br/>
  Date : {{ selected.date }}<br/>
  Heure : Entre 9h et 12h<br/>
  <v-btn mb-3 @click="$store.dispatch('dispose_ui_city'); confirmMessage=false; message=''" ><undo decorative :size="16"/>Revenir au choix des communes</v-btn>
</v-card-text>
</v-card>


</v-layout>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, email, numeric } from 'vuelidate/lib/validators'
// import VueTelInput from 'vue-tel-input'

export default {
components: {
    // VueTelInput,
  },
  mixins: [validationMixin],
  validations: {
    nom: { required, maxLength: maxLength(10) },
    prenom: { required },
    email: { required, email },
    telephone: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
    message: { required }
  },
  name: 'formContact',
  data: function() {
    return {
      confirmMessage: false,
      confirmText: '',
      nom: '',
      prenom: '',
      telephone: '',
      email: '',
      message: ''
    }
  },
  props: ['formMessage'],
  mounted () {
    this.$vuetify.rtl = false
  },
  watch: {
    formMessage: function (val) {
      this.message = val
    }
  },
  methods: {
    contactSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
          return
      }

      let datas = {
        'subject': 'Demande de conseil',
        'nom': ['nom', this.nom],
        'prenom': ['prénom', this.prenom],
        'telephone': ['téléphone', this.telephone],
        'email': ['email', this.email],
        'message': ['message', this.message],
        'destinataire': 'ripery.l@caue971.org',
        'commune_id': ['commune', this.selected.cityId],
        'date_rdv': ['date souhaitée', this.selected.date],
        'conseiller_id': ['conseiller', this.selected.conseillersIds],
        'template_id': 1391045
      }

      axios({
        method: 'POST',
        url: this.appParameters.urlAgenda + 'mail',
        // url: 'http://www.caue971.org/api/v1/mail',
        responseType: 'json',
        crossDomain: true,
        data: datas
      })
        .then(response => {
          console.log(response.data.Messages[0].Status)
          if (response.data.Messages[0].Status === "success") {
            this.confirmText = 'Merci, votre message a bien été envoyé.'
            this.confirmMessage = true
          } else {
            this.confirmText = 'Malheureusement votre message n\'a pas pu être envoyé. Nous vous invitons à réessayer plus tard ou contacter directement au 0590 81 83 85.'
            this.confirmMessage = true
          }
        })

      axios({
        method: 'POST',
        url: this.appParameters.urlAgenda + 'demandes/new',
        // url: 'http://www.caue971.org/api/v1/mail',
        responseType: 'json',
        crossDomain: true,
        data: datas
      })
        .then(response => {
          console.log(response)
        })
    },
    clear () {
      this.$v.$reset()
      this.nom = ''
      this.prenom = ''
      this.telephone = ''
      this.email = ''
      this.message = ''
    }
  },
  computed: {
    ...mapState(['appParameters','conseils', 'ui', 'selected']),
    nomErrors () {
      const errors = []
      if (!this.$v.nom.$dirty) return errors
      !this.$v.nom.required && errors.push('Le nom est obligatoire.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push('L\'email est obligatoire.')
      !this.$v.email.email && errors.push('L\'email est malformé.')
      return errors
    },
    telephoneErrors () {
      const errors = []
      if (!this.$v.telephone.$dirty) return errors
      !this.$v.telephone.required && errors.push('Un numéro de téléphone est requis.')
      !this.$v.telephone.minLength && errors.push('Un numéro de 10 chiffres est requis')
      !this.$v.telephone.maxLength && errors.push('Un numéro de 10 chiffres est requis')
      !this.$v.telephone.numeric && errors.push('Seul les chiffres sont autorisés')
      return errors
    },
    messageErrors () {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.required && errors.push('Le message est obligatoire')
      return errors
    }
  }
}
</script>

<style scoped>

.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) .v-label {
  left: 0px !important;
  right: auto !important;
  position: absolute;
}

.txtf>.v-input__control>.v-input__slot>v-text-field__slot>.v-label {
text-align: left;
left: 0px !important;
right: auto !important;
}

.v-messages__message {
  color: red !important;
  font-weight: bold;
}

.v-text-field--outlined>.v-input__control>.v-input__slot {
    background: white;
}

</style>