<template>

<v-container>


<v-row v-show="ui.closestDateShow">
<closestDate @showFormContact="showFormContact" style="justify-content: center"></closestDate>
</v-row>




<v-row align="center" justify="center">
  <v-col class="col-12" v-show="ui.formShow">
     <v-btn block class="col-12 col-lg-6" @click="showCities" height="95">
       <v-col>
       <v-row style="justify-content:center"><undo decorative :size="16"/><span style="font-size:9px"> changer la commune ?</span></v-row>
       <v-row style="justify-content:center"><v-chip>{{ selected.cityName }}<check-bold/></v-chip></v-row>
       </v-col>
     </v-btn>
  </v-col>

  <v-col class="col-12" v-show="ui.formShow">
    <v-btn block class="col-12 col-lg-6" @click="showDates(selected.cityIndex)" height="95">
       <v-col>
       <v-row style="justify-content:center"><undo decorative :size="16"/><span style="font-size:9px"> changer la date ?</span></v-row>
       <v-row style="justify-content:center"><v-chip>{{ convertDate(selected.date) }}<check-bold/></v-chip></v-row>
       </v-col>
    </v-btn>
  </v-col>
</v-row>

<v-row>





<v-col id="listing-communes" class="col-12 col-sm-6 listing-choix mt-0" v-show="ui.cityShow">

<!-- <v-col cols="12" v-show="ui.cityShow"><strong>Recherchez ou sélectionnez une commune</strong><br/></v-col>-->

<v-card outlined class="col-12 mx-auto pb-0">
<v-col v-show="ui.searchShow" class="list-header">
      <v-text-field v-model="search" placeholder="recherchez votre commune" dense hide-details>
              <template v-slot:prepend-inner>
                <map-search></map-search>
            </template>
      </v-text-field>
</v-col>

    <v-list id="communes-list" two-line>

      <v-list-item-group color="primary">
        <template v-for="(item, i) in cityList">
        <v-list-item :key="i" @click="showDates(i)" v-show="!item.hidden & item.has_google_dates">
          <v-list-item-content @mouseover="showByIndex = i" @mouseout="showByIndex = null">
            <v-list-item-title v-text="item.nom"></v-list-item-title>
            <v-list-item-subtitle v-show="showByIndex === i">Voir les dates de cette ville</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
            <v-divider :key="'divider' + i" v-show="!item.hidden & item.has_google_dates"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</v-col>



  <datesCommune 
  :datesOfCity="datesOfCity" 
  @showFormContact="showFormContact">
  </datesCommune>



<v-col class="col-12 mx-auto" v-show="ui.formShow">
  <formContact 
  :formMessage="formMessage">
  </formContact>
</v-col>



</v-row>



<v-row v-show="ui.dateShow|ui.formShow">
<v-col class="col-12 mx-auto" style="justify-content: center">
<conseillerDetails></conseillerDetails>
</v-col>
</v-row>

</v-container>

</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import datesCommune from '@/components/dates.vue'
import formContact from '@/components/form-contact.vue'
import closestDate from '@/components/closest-date.vue'
import conseillerDetails from '@/components/conseiller-details.vue'

export default {
  name: 'communes',
  components: {datesCommune, formContact, closestDate, conseillerDetails},
  data: function () {
    return {
      showByIndex: null,
      datesOfCity: null,
      formMessage: null,
      search: null
    }
  },
  methods: {
    convertDate (date) {
      return moment(date).format('dddd DD MMMM YYYY')
    },
    showDates (i) {
      this.$store.dispatch('store_city_index', i)
      this.$store.dispatch('store_city_id', this.conseils.communes[i].id)
      this.$store.dispatch('store_city_name', this.conseils.communes[i].nom)
      this.$store.dispatch('store_conseiller_id', this.conseils.communes[i].id)
      this.datesOfCity = this.datesFromGoogleAgenda
      this.$store.dispatch('dispose_ui_date')
    },
    showCities () {
      this.datesOfCity = null
      this.$store.dispatch('dispose_ui_city')
    },
    showFormContact (datas) {
      this.formMessage = 'Bonjour,\r\n Je suis intéressé(e) par le point-conseil du ' + datas.date + ' qui se tiendra dans la commune de ' + datas.cityNameSelected + '\r\n '
      if (datas.conseillers.length > 0) this.formMessage = this.formMessage + '\r\n'
      this.formMessage = this.formMessage + 'Concernant le sujet suivant : [ DECRIVEZ ICI LE SUJET A ABORDER AVEC LE CONSEILLER-ARCHITECTE]'
      this.$store.dispatch('dispose_ui_contact')
    },
    communesHasDate () {
      let communesFound = []
      if (this.conseils.googleAgenda !== null && this.conseils.googleAgenda !== '') {
        communesFound = this.conseils.googleAgenda.filter((event) => {
        let addThis = false
        if (this.conseils.communes) {
          this.conseils.communes.forEach ((commune) => {
            if (event.summary.toLowerCase().includes(commune.nom.toLowerCase())) {
              commune.has_google_dates = true
              // On test avec some() si la commune à déjà été rajoutée. Si some() dis oui, on n'autorise pas l'ajout (addThis sera égal à false)
              addThis = (communesFound.some ((item) => {return item.nom.toLowerCase() === commune.nom.toLowerCase()})) ? false : true
            }
          })
        }
          return addThis
        })
      }
      return communesFound
    }
  },
  mounted () {
    moment.locale('fr')
    this.$store.dispatch('dispose_ui_city')
    console.log(this.$vuetify.application)
  },
  computed: {
    ...mapState(['appParameters','conseils', 'ui', 'selected']),
    datesFromGoogleAgenda: function () {
      let filteredDates = this.conseils.googleAgenda.filter((city) => {
        let cityName = this.conseils.communes[this.selected.cityIndex].nom
        return city.summary.toLowerCase().includes(cityName.toLowerCase())
      })
      return filteredDates
    },
    cityList: function () {
      let filterCity = []
      if (this.search !== null && this.search !== '') {
          filterCity = this.conseils.communes.filter((city) => {
            // Afin de ne pas perdre l'index de chaque élément (et faire des liaisons + tard) on gère la propriété hidden, plutôt que supprimé l'item de cityList
            if (this.search === null || this.search === '' || city.nom.toLowerCase().includes(this.search.toLowerCase())) {
              city.hidden = false
              return true
            } else {
              city.hidden = true
              return true
            }
        })
      } else {
        filterCity = this.conseils.communes
        // Remettre toutes les communes visible
        for (var key in filterCity) {
          filterCity[key].hidden = false
        }
      }
      return filterCity
    }
  },
  watch: {
    'conseils.googleAgenda': function () {
      this.communesHasDate()
    }
  }
}
</script>

<style scoped>
  

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }


  .overline {
    border-bottom: 1px solid #cec5c5;
  }
  #listing-communes {
     overflow-y: hidden;
  }
  .listing-choix {
    min-height: 325px;
    max-height: 325px;
    overflow-y: scroll;
  }

  .listing-choix hr {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  
  .listing-choix #communes-list {

    max-height: 230px;
    overflow: auto;

	background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background:
		/* Shadow covers */
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		
		/* Shadows */
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	
	/* Opera doesn’t support this in the shorthand */
	background-attachment: local, local, scroll, scroll;

  }

  .list-header {
    border-bottom: 1px solid #dedada;
  }

  #list-decoration-gradient {
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 341px;
    left: 0px;
    overflow: hidden;
    display: none;
  }

  #gradient-line {
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0px;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,0.42620798319327735) 0%, rgba(255,255,255,0) 100%);
  }
  #gradient-bottom {
    width: 135%;
    height: 1125px;
    position: absolute;
    bottom: -618%;
    left: -15%;
    background-position: 0 255px;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgb(2, 0, 36) 0%, rgba(255, 255, 255, 0) 49%);
  }
    

/* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
/* peuvent utiliser différentes fonctions de durée et de temps.  */
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
