<template>
<v-row xs12 sm12>

<v-col cols="12" v-if="selected.cityName">

<v-card id="conseiller-details-lieu">
  <v-card-title class="headline text-uppercase justify-center">
  <strong>{{ selected.cityName }}</strong>
  </v-card-title>
  <v-card-subtitle class="pb-0">Où nous trouver ?</v-card-subtitle>
    <v-card-text class="subtitle-1">
  <v-divider></v-divider>

  <span><strong>{{conseils.communes[selected.cityIndex].lieu_nom}}</strong></span><br/>
  <span>{{conseils.communes[selected.cityIndex].lieu_adresse}}</span><br/>
  <span>{{conseils.communes[selected.cityIndex].lieu_telephone}}</span><br/>

    </v-card-text>
</v-card>
</v-col>

<!-- affichage des conseillers interdit par la loi -->

</v-row>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment-timezone'
  
export default {
  name: 'conseillerDetails',
  components: {},
  data: function () {
    return {
    }
  },
  methods: {
    convertDate (date) {
      return moment(date).format('dddd DD MMMM YYYY')
    },
    getConseillers () {
      axios({
        method: 'GET',
        url: this.appParameters.urlAgenda + 'conseillers',
        responseType: 'json'
      })
        .then(response => {
          this.$store.dispatch('store_conseillers', response.data)
        })
    }
  },
  mounted () {
    moment.locale('fr')
    if (this.conseils.conseillers == null) this.$store.dispatch('get_conseillers')
  },
  computed: {
    ...mapState(['appParameters','conseils', 'ui', 'selected']),
    conseiller: function () {
      let filteredConseillers = []
      if (this.conseils.conseillers !== null && this.selected.cityName){
        filteredConseillers = this.conseils.conseillers.filter((conseillers) => {
          let communeId = this.conseils.communes[this.selected.cityIndex].id
          if (conseillers.commune_id == communeId) return conseillers
        })
      }
      return filteredConseillers
    }
  }
}
</script>

<style scoped>
.v-list-item__action--stack { flex-direction: row; }
</style>
