<template>
<v-layout class="col-12 align-content-center justify-content-center" style="padding-top: 0px; padding-bottom: 0px">

<v-card id="closest-date" flat v-if="conseils.closestDate" class="col-12" style="padding-top: 0px; padding-bottom: 0px">
    <v-card-text>
      <div class="mb-2" style="font-size: 1.2rem; text-transform:uppercase;"><strong>Votre prochain point-conseil</strong></div>

      <v-row class="justify-center">
      <v-col class="col-12 col-xl-6">
      <v-card id="block-date" class="elevation-0" v-if="conseils.closestDate" >
        <v-card-title class="headline" style="justify-content:center; color:#bc0c0c"><span class="black px-1 text-uppercase white--text">{{closestDate.city.nom}}</span></v-card-title>
        <v-card-text style="color:#570a0a" class="d-xl-none d-lg-flex headline justify-center font-weight-black text-uppercase">
          {{convertDate(conseils.closestDate.start)}}
        </v-card-text>
        <v-card-actions class="justify-center">
              <v-btn class="d-lg-flex d-xl-none" @click="showFormContact()"><calendar-plus-icon/>DEMANDE RENSEIGNEMENT</v-btn>
              <v-btn class="d-none d-xl-flex" @click="showFormContact()"><calendar-plus-icon/>{{convertDate(conseils.closestDate.start)}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    </v-row>
    </v-card-text>
</v-card>


</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment-timezone'
  
export default {
  name: 'closestDate',
  components: {},
  data: function () {
    return {
      showByIndex: null,
      datesModel: []
    }
  },
  methods: {
    convertDate (date) {
      return moment(date).format('dddd DD MMMM YYYY')
    },
    showFormContact () {
      let dateString = this.convertDate(this.conseils.closestDate.start)
      this.$store.dispatch('store_date', this.conseils.closestDate.start)
      this.$store.dispatch('store_city_name', this.closestDate.city.nom)
      this.$store.dispatch('store_city_index', this.closestDate.cityIndexSelected)
      this.$store.dispatch('store_city_id', this.conseils.communes[this.closestDate.cityIndexSelected].id)
      this.$store.dispatch('store_conseiller_id', this.conseils.communes[this.closestDate.cityIndexSelected].id)
      this.$emit('showFormContact', {date: dateString, conseillers: this.conseiller, cityNameSelected: this.closestDate.city.nom})
    },
    getClosestDate () {
      axios({
        method: 'GET',
        url: this.appParameters.urlAgenda + 'events/closest',
        responseType: 'json'
      })
        .then(response => {
          this.$store.dispatch('store_closest_date', response.data)
        })
    }
  },
  mounted () {
    moment.locale('fr')
    if (this.conseils.closestDate == null) this.getClosestDate()
  },
  computed: {
    ...mapState(['appParameters','conseils', 'ui', 'selected']),
    closestDate: function () {
      let filterClosest = {}
      let closest = this.conseils.closestDate.summary

      this.conseils.communes.forEach((city, index) => {
        
        if (city.nom.toLowerCase().includes(closest.toLowerCase())) {
          filterClosest = {city: city, cityIndexSelected: index}
        }

      })

      return filterClosest
    },
    conseiller: function () {
      let filteredConseillers = []
      if (this.conseils.conseillers !== null && this.closestDate.cityIndexSelected){
        filteredConseillers = this.conseils.conseillers.filter((conseillers) => {
          let communeId = this.conseils.communes[this.closestDate.cityIndexSelected].id
          if (conseillers.commune_id == communeId) return conseillers
        })
      }
      return filteredConseillers
    }
  }
}
</script>

<style scoped>
.v-list-item__action--stack { flex-direction: row; }
</style>
