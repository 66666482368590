/*
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
*/

import Vue from 'vue'
import axios from 'axios'
import router from './router'
import store from './store'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import ChevronLeftBox from 'vue-material-design-icons/ChevronLeftBox.vue'
import DeleteForeverIcon from 'vue-material-design-icons/DeleteForever.vue'
import CalendarPlus from 'vue-material-design-icons/CalendarPlus.vue'
import CheckBold from 'vue-material-design-icons/CheckBold.vue'
import Undo from 'vue-material-design-icons/Undo.vue'
import ArrowDownBox from 'vue-material-design-icons/ArrowDownBox.vue'
import DatabaseSearch from 'vue-material-design-icons/DatabaseSearch.vue'
import MapSearch from 'vue-material-design-icons/MapSearch.vue'
import CommentSearch from 'vue-material-design-icons/CommentSearch.vue'
import FeatureSearch from 'vue-material-design-icons/FeatureSearch.vue'
// import VueTelInput from 'vue-tel-input'

Vue.component('chevron-left-box', ChevronLeftBox)
Vue.component('delete-forever-icon', DeleteForeverIcon)
Vue.component('calendar-plus-icon', CalendarPlus)
Vue.component('check-bold', CheckBold)
Vue.component('undo', Undo)
Vue.component('arrow-down-box', ArrowDownBox)
Vue.component('database-search', DatabaseSearch)
Vue.component('map-search', MapSearch)
Vue.component('comment-search', CommentSearch)
Vue.component('feature-search', FeatureSearch)
// Vue.component('vue-tel-input', VueTelInput)
Vue.use(Vuetify, { rtl: false })
Vue.use(vueCustomElement)

// Vue.use(VueTelInput)
Vue.http = Vue.prototype.$http = axios

App.store = store
App.router = router

Vue.http.get('https://www.caue971.org/api/conseil/v1/events/closest')
  .then((response) => {
    store.dispatch('store_closest_date', response.data[0])
    Vue.customElement('app-points-conseils', App, {vuetify: new Vuetify()})
  })

Vue.config.productionTip = false

/*
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
*/