import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// url: 'http://localhost/caue-website/public/api/v1/',
// urlAgenda: 'http://localhost/caue-website/public/api/conseil/v1/',
// url: 'https://www.caue971.org/api/v1/',
// urlAgenda: 'https://www.caue971.org/api/conseil/v1/',

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appParameters: {
      url: 'https://www.caue971.org/api/v1/',
      urlAgenda: 'https://www.caue971.org/api/conseil/v1/',
      adminMode: false
    },
    ui: {
      dateShow: false,
      cityShow: true,
      formShow: false,
      searchShow: true,
      closestDateShow: true
    },
    conseils: {
      communes: null,
      googleAgenda: null,
      conseillers: null,
      closestDate: null
    },
    selected: {
      cityIndex: null,
      cityId: null,
      cityName: null,
      conseillersIds: null,
      date: null
    }
  },
  actions: {
    // communes Actions
    store_communes (store, datas) {
      store.commit('store_communes', datas)
    },
    // googleAgenda Actions
    store_googleagenda (store, datas) {
      store.commit('store_googleagenda', datas)
    },
    delete_googleagenda_event (store, datas) {
      store.commit('delete_googleagenda_event', datas)
    },
    // conseillers Actions
    get_conseillers ({commit, state}) {
      axios({
        method: 'GET',
        url: state.appParameters.urlAgenda + 'conseillers',
        responseType: 'json'
      })
      .then(response => { commit('store_conseillers', response.data) })
    },
    store_conseillers (store, datas) {
      store.commit('store_conseillers', datas)
    },
    // closestDate Actions
    store_closest_date (store, datas) {
      store.commit('store_closest_date', datas)
    },
    // dispose ui Actions
    dispose_ui_city (store) {
      store.commit('dispose_ui_city')
    },
    dispose_ui_date (store) {
      store.commit('dispose_ui_date')
    },
    dispose_ui_contact (store) {
      store.commit('dispose_ui_contact')
    },
    // selected Actions
    store_city_index (store, datas){
      store.commit('store_city_index', datas)
    },
    store_city_id (store, datas){
      store.commit('store_city_id', datas)
    },
    store_city_name (store, datas){
      store.commit('store_city_name', datas)
    },
    store_date (store, datas){
      store.commit('store_date', datas)
    },
    store_conseiller_id (store, datas){
      store.commit('store_conseiller_id', datas)
    }
  },
  mutations: {
    // communes Mutations
    store_communes (state, datas) {
      for (var key in datas) {
        datas[key].hidden = false
        datas[key].has_google_dates = false
        
      }
      state.conseils.communes = datas
    },
    // googleAgenda Mutations
    store_googleagenda (state, datas) {
      state.conseils.googleAgenda = datas
    },
    delete_googleagenda_event (state, datas) {
      state.conseils.googleAgenda.filter((commune, i) => {
        let dateToDelete = commune.id.toLowerCase().includes(datas.toLowerCase())
        if (dateToDelete) {
          state.conseils.googleAgenda.splice(i, 1)
        }
      })
    },
    // conseillers Mutations
    store_conseillers (state, datas) {
      state.conseils.conseillers = datas
    },
    // closestDate Mutations
    store_closest_date (state, datas) {
      state.conseils.closestDate = datas
    },
    // dispose ui Mutations
    dispose_ui_city (state) {
      state.ui.dateShow = true
      state.ui.cityShow = true
      state.ui.formShow = false
      state.ui.searchShow = true
      state.ui.closestDateShow = true
    },
    dispose_ui_date (state) {
      state.ui.dateShow = true
      state.ui.cityShow = true
      state.ui.formShow = false
      state.ui.closestDateShow = true
      state.ui.searchShow = true
    },
    dispose_ui_contact (state) {
      state.ui.dateShow = false
      state.ui.cityShow = false
      state.ui.formShow = true
      state.ui.searchShow = false
      state.ui.closestDateShow = false
    },
    // selected Mutations
    store_city_index (state, datas){
      state.selected.cityIndex = datas
    },
    store_city_id (state, datas){
      state.selected.cityId = datas
    },
    store_city_name (state, datas){
      state.selected.cityName = datas
    },
    store_date (state, datas){
      state.selected.date = datas
    },
    store_conseiller_id (state, datas){
      let conseillersList = []
      for (var key in state.conseils.conseillers) {
        if (state.conseils.conseillers[key].commune_id === datas) conseillersList.push(state.conseils.conseillers[key].conseiller_source_id)
      }
      state.selected.conseillersIds = conseillersList
    }
  }
})
